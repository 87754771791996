import React, { useState, useEffect } from 'react';
import './CSS/PerosnalPage.css';
import Navbar from '../components/Navbar/Navbar';
import Scrollbar from '../components/Scrollbar/Scrollbar';
import Footer from '../components/Footer/Footer';

const PersonalPage = ({ onOpenPopup }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    useEffect(() => {
        document.title = 'Personal | Faiz Accountax';
    }, []);

    return (
        <>
            <Navbar onOpenPopup={onOpenPopup} />
            <Scrollbar onOpenPopup={onOpenPopup} />
            <div className="buisness-top">
                <h1 className='buisness-head'>Personal Taxes</h1>
                <hr />
                <div className='buisnesspage'>
                    <div className="buisness-left">
                        <div className="buisness1">
                            <h1 className='buisness-heading'>Personal Tax Return</h1>
                            <p className='buisness-para'>
                                Personal taxes in the UK can seem complex, but understanding the system and key deadlines is crucial for ensuring compliance and optimizing your financial situation. Here’s a comprehensive overview to help you navigate the intricacies of personal taxes and see how Faiz Accountax can assist you with tax returns and planning.
                            </p>
                        </div>

                        <div className="buisness2">
                            <h1>How Personal Taxes Work in the UK</h1>
                            <div className="buisness2-lists">
                                <h2>1. Income Tax</h2>
                                <ul>
                                    <li>Levied on earnings from employment, self-employment, pensions, and savings.</li>
                                </ul>

                                <h2>2. National Insurance Contributions (NICs)</h2>
                                <ul>
                                    <li>Mandatory contributions for social security benefits and pensions.</li>
                                </ul>

                                <h2>3. Capital Gains Tax</h2>
                                <ul>
                                    <li>Tax on the profit when you sell or dispose of an asset that has increased in value.</li>
                                </ul>

                                <h2>4. Inheritance Tax</h2>
                                <ul>
                                    <li>Tax on the estate (property, money, and possessions) of someone who has died.</li>
                                </ul>

                                <h2>5. Dividend Tax</h2>
                                <ul>
                                    <li>Tax on income from dividends if you own shares in a company.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="buisness2">
                            <h1>Key Deadlines</h1>
                            <div className="buisness2-lists">
                                <h2>Self-Assessment Registration</h2>
                                <ul>
                                    <li>You must register with HMRC by 5th October following the end of the tax year.</li>
                                </ul>

                                <h2>Paper Tax Returns</h2>
                                <ul>
                                    <li>The deadline for submitting paper tax returns is 31st October following the end of the tax year.</li>
                                </ul>

                                <h2>Online Tax Returns</h2>
                                <ul>
                                    <li>The deadline for filing online tax returns is 31st January following the end of the tax year.</li>
                                </ul>

                                <h2>Tax Payment</h2>
                                <ul>
                                    <li>Any tax due must be paid by 31st January following the end of the tax year.</li>
                                    <li>For payments on account, the second payment is due by 31st July.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="buisness2">
                            <h1>When to Submit a Tax Return</h1>
                            <ul>
                                <li>You need to submit a self-assessment tax return if you are self-employed, a sole trader, or a partner in a business partnership.</li>
                                <li>You have untaxed income, such as rental income, investment income, or foreign income.</li>
                                <li>Your taxable income was over £100,000.</li>
                                <li>You need to claim certain tax reliefs or expenses.</li>
                            </ul>
                        </div>

                        <div className="buisness2">
                            <h1>How Faiz Accountax Can Help</h1>
                            <div className="buisness2-lists">
                                <p>
                                    Navigating the UK’s tax system can be challenging, but Faiz Accountax is here to help. Our comprehensive personal tax services include:
                                </p>
                                <ul>
                                    <li><strong>Tax Returns:</strong> We assist with the preparation and submission of your self-assessment tax returns, ensuring accuracy and compliance with HMRC requirements.</li>
                                    <li><strong>Tax Planning:</strong> We offer personalized tax planning services to help you minimize your tax liabilities and maximize your financial health.</li>
                                    <li><strong>Expert Advice:</strong> Our team provides expert advice and support throughout the year, ensuring you understand your tax obligations and opportunities.</li>
                                    <li><strong>HMRC Representation:</strong> We act as your representatives, handling all communications and negotiations with HMRC on your behalf.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="buisness1">
                            <h1 className='buisness-heading'>Get Started</h1>
                            <p className='buisness-para'>
                                Understanding and managing personal taxes in the UK requires knowledge and attention to detail. At Faiz Accountax, we provide the expertise and support you need to navigate the tax system confidently. From preparing and filing tax returns to offering strategic tax planning, we ensure you stay compliant and make informed financial decisions. Contact us today to learn more about how we can assist you with your personal tax needs.
                            </p>
                        </div>
                    </div>

                    <div className="buisness-right">
                        <div className="buisness-right-box">
                            <h2>Have Any Question?</h2>
                            <p>If you have any questions or need more information about our services, don’t hesitate to get in touch. Our team is here to provide the answers and support you need.</p>
                            <div className="buisness-icon">
                                <i className="fa-solid fa-phone"></i>
                                <p>0208 191 0732</p>
                            </div>
                            <div className="buisness-icon">
                                <i className="fa-solid fa-envelope"></i>
                                <p>info@faizaccountax.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PersonalPage;
