import React, { useState } from 'react';
import './Popup.css';

const Popup = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        businessType: '',
        turnover: '',
        payroll: '',
        vatReturns: '',
        bookkeeping: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleDropdownChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div id="pop" className="pop">
                <span className="close-button" onClick={onClose}>&times;</span>
                <div className="pop-qoute-table">
                    <div className="pop-text-input">
                        <div className="pop-input-block">
                            <input
                                className='pop-input-left'
                                placeholder='Enter name'
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input
                                className='pop-input-left'
                                placeholder='Enter email'
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <input
                                className='pop-input-left'
                                placeholder='Enter telephone number'
                                type="text"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />

                            <div className="dropdown extra">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {formData.businessType || 'Select business type'}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'Sole Trader')}>Sole Trader</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'Limited Company')}>Limited Company</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'Partnership')}>Partnership</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'LLP')}>LLP</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="pop-block">
                            <div className="dropdown extra">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {formData.turnover || 'Select turnover'}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('turnover', '£50 - £5,000')}>£50 - £5,000</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('turnover', '£5,000 - £10,000')}>£5,000 - £10,000</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('turnover', '£10,000 - £20,000')}>£10,000 - £20,000</a></li>
                                    {/* Add more turnover options as needed */}
                                </ul>
                            </div>

                            <div className="dropdown extra">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {formData.payroll || 'Need payroll managing?'}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('payroll', 'Yes')}>Yes</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('payroll', 'No')}>No</a></li>
                                </ul>
                            </div>

                            <div className="dropdown extra">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {formData.vatReturns || 'Need VAT returns completing?'}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('vatReturns', 'Yes')}>Yes</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('vatReturns', 'No')}>No</a></li>
                                </ul>
                            </div>

                            <div className="dropdown extra">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {formData.bookkeeping || 'Need us to complete your bookkeeping?'}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('bookkeeping', 'Yes')}>Yes</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleDropdownChange('bookkeeping', 'No')}>No</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pop-icon-btn">
                    <div className="pop-btn">
                        <i className="fa-solid fa-calculator calculator-3"></i>
                        <button className='pop-btn'>Get An Instant Quote</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
