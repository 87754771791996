import React, { useState, useEffect } from 'react';
import './CSS/BuisnessPage.css';

import Navbar from '../components/Navbar/Navbar'
import Scrollbar from '../components/Scrollbar/Scrollbar'
import Footer from '../components/Footer/Footer';

const BuisnessPage = ({ onOpenPopup }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [menu, setMenu] = useState("home")

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);
    useEffect(() => {
        document.title = 'Buisness  | Faiz Accountax';
    }, []);
    return (
        <>
            <Navbar onOpenPopup={onOpenPopup} />
            <Scrollbar onOpenPopup={onOpenPopup} />
            <div className="buisness-top">
                <h1 className='buisness-head'>Buisness Taxes</h1>
                <hr />
                <div className='buisnesspage'>
                    <div className="buisness-left">
                        <div className="buisness1">
                            <h1 className='buisness-heading'>Business Taxes</h1>
                            <p className='buisness-para'>
                                Navigating the complexities of business taxes in the UK is essential for compliance and financial optimization. Whether you operate as a limited company, sole trader, or partnership, understanding your tax obligations and opportunities can significantly impact your business’s success. Here, we provide a comprehensive overview of business taxes and explain how Faiz Accountax can assist you in managing them effectively.
                            </p>
                        </div>

                        <div className="buisness2">
                            <h1>Types of Business Taxes</h1>
                            <div className="buisness2-lists">
                                <h2>1. Corporation Tax</h2>
                                <ul>
                                    <li><strong>Who Pays:</strong> Limited companies and some other organizations.</li>
                                    <li><strong>Filing:</strong> Companies must file a Company Tax Return (CT600) and pay any Corporation Tax due.</li>
                                </ul>

                                <h2>2. Income Tax for Sole Traders and Partnerships</h2>
                                <ul>
                                    <li><strong>Sole Traders:</strong> Pay income tax on business profits via the self-assessment system.</li>
                                    <li><strong>Partnerships:</strong> Each partner pays income tax on their share of the profits.</li>
                                    <li><strong>Rates:</strong> The rates are the same as for personal income tax, including basic, higher, and additional rates.</li>
                                </ul>

                                <h2>3. Value Added Tax (VAT)</h2>
                                <ul>
                                    <li><strong>Who Pays:</strong> Businesses with a taxable turnover above the VAT threshold (currently £90,000).</li>
                                    <li><strong>Rate:</strong> Standard rate is 20%, with reduced rates for certain goods and services.</li>
                                    <li><strong>Filing:</strong> Quarterly VAT returns must be submitted, and VAT payments made accordingly.</li>
                                </ul>

                                <h2>4. National Insurance Contributions (NICs)</h2>
                                <ul>
                                    <li><strong>Companies:</strong> Employers pay Class 1 NICs on employees’ earnings.</li>
                                    <li><strong>Sole Traders:</strong> Pay Class 2 and Class 4 NICs based on profits.</li>
                                </ul>

                                <h2>5. Capital Gains Tax (CGT)</h2>
                                <ul>
                                    <li><strong>Who Pays:</strong> Businesses that sell or dispose of an asset for profit.</li>
                                    <li><strong>Rates:</strong> Varies based on whether the asset qualifies for Entrepreneurs’ Relief or other reliefs.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="buisness2">
                            <h1>Key Deadlines</h1>
                            <div className="buisness2-lists">
                                <h2>1. Corporation Tax</h2>
                                <ul>
                                    <li>Payment is due nine months and one day after the end of the accounting period.</li>
                                    <li>Filing the Company Tax Return (CT600) is due 12 months after the end of the accounting period.</li>
                                </ul>

                                <h2>2. Self-Assessment for Sole Traders and Partnerships:</h2>
                                <ul>
                                    <li>Register by 5th October following the end of the tax year.</li>
                                    <li>File paper returns by 31st October and online returns by 31st January.</li>
                                    <li>Pay any tax due by 31st January, with a second payment on account by 31st July.</li>
                                </ul>

                                <h2>3. Value Added Tax (VAT)</h2>
                                <ul>
                                    <li>Quarterly returns and payments are typically due one month and seven days after the end of the VAT period.</li>
                                </ul>

                                <h2>4. National Insurance Contributions (NICs)</h2>
                                <ul>
                                    <li><strong>Companies:</strong> Employers pay Class 1 NICs on employees’ earnings.</li>
                                    <li><strong>Sole Traders:</strong> Pay Class 2 and Class 4 NICs based on profits.</li>
                                </ul>

                                <h2>5. Capital Gains Tax (CGT)</h2>
                                <ul>
                                    <li>Report and pay within 60 days of selling UK residential property.</li>
                                    <li>For other assets, report and pay by 31st January following the tax year in which the gain was made.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="buisness2">
                            <h1>How We Can Help</h1>
                            <div className="buisness2-lists">
                                <p>
                                    At Faiz Accountax, we offer a comprehensive range of services to help businesses manage their tax obligations efficiently and take advantage of available reliefs and allowances. Here’s how we can assist:
                                </p>
                                <ul>
                                    <li><strong>Company Tax Returns:</strong> We handle the preparation and filing of your Company Tax Return (CT600), ensuring accuracy and compliance with HMRC requirements. Our experts also identify opportunities for tax savings and optimize your Corporation Tax position.</li>
                                    <li><strong>Income Tax for Sole Traders and Partnerships:</strong> Our team assists with the preparation and filing of self-assessment tax returns, ensuring that all allowable expenses are claimed and you pay the correct amount of tax. We help you navigate the complexities of income tax, NICs, and related obligations.</li>
                                    <li><strong>VAT Compliance:</strong> We manage your VAT registration, prepare and file your VAT returns, and ensure timely payments. Our services also include advising on VAT schemes that might benefit your business, such as the Flat Rate Scheme or the Annual Accounting Scheme.</li>
                                    <li><strong>Capital Allowances:</strong> We help you maximize your claims for capital allowances on business assets, reducing your taxable profits and improving cash flow. Our experts identify qualifying expenditures and ensure accurate reporting.</li>
                                    <li><strong>Capital Gains Tax:</strong> We provide guidance on reporting and paying CGT, advising on reliefs such as Entrepreneurs’ Relief to minimize your tax liability. Our services ensure that you meet all reporting requirements and deadlines.</li>
                                    <li><strong>Tax Planning and Advice:</strong> Effective tax planning is crucial for business success. We offer strategic tax advice tailored to your business structure and goals, helping you plan for future growth while remaining tax-efficient.</li>
                                    <li><strong>Representation and Support:</strong> We act as your representatives in dealings with HMRC, handling inquiries, audits, and disputes. Our proactive approach ensures that you stay compliant and can focus on running your business.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="buisness2">
                            <h1 className='buisness-heading'>Get Started</h1>
                            <p className='buisness-para'>
                                Understanding and managing business taxes in the UK requires expertise and attention to detail. At Faiz Accountax, we provide the knowledge and support you need to navigate the tax landscape confidently. From preparing and filing tax returns to offering strategic tax planning, we are here to help your business thrive. Contact us today to learn more about how we can assist you with your business tax needs.
                            </p>
                        </div>
                    </div>
                    <div className="buisness-right">
                        <div className="buisness-right-box">
                            <h2>Have Any Question?</h2>
                            <p>If you have any questions or need more information about our services, don’t hesitate to get in touch. Our team is here to provide the answers and support you need.</p>
                            <div className="buisness-icon">
                                <i class="fa-solid fa-phone"></i>

                                <p>0208 191 0732</p>
                            </div>
                            <div className="buisness-icon">
                                <i class="fa-solid fa-envelope"></i>
                                <p>info@faizaccountax.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BuisnessPage;
