import React, { useState } from 'react';
import './Qoute.css';

const Qoute = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    businessType: '',
    turnover: '',
    payroll: '',
    vatReturns: '',
    bookkeeping: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle dropdown selection
  const handleDropdownChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div id='qoute' className='qoute'>
      <h1 className='qoute-heading'>Instant Qoute</h1>
      <p className='qoute-para'>We charge a monthly fee based on your business type</p>
      <div className="qoute-table">
        <div className="text-input">
          <div className="input-block">
            <input
              className='input-left'
              placeholder='Enter name'
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              className='input-left'
              placeholder='Enter email'
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              className='input-left'
              placeholder='Enter telephone number'
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />

            {/* Business type dropdown */}
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {formData.businessType || 'Select business type'}
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'Sole Trader')}>Sole Trader</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'Limited Company')}>Limited Company</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'Partnership')}>Partnership</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('businessType', 'LLP')}>LLP</a></li>
              </ul>
            </div>
          </div>

          <div className="input-block">
            {/* Turnover dropdown */}
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {formData.turnover || 'Select turnover'}
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('turnover', '£50 - £5,000')}>£50 - £5,000</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('turnover', '£5,000 - £10,000')}>£5,000 - £10,000</a></li>
                {/* Add more turnover ranges as needed */}
              </ul>
            </div>

            {/* Payroll management dropdown */}
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {formData.payroll || 'Need payroll managing?'}
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('payroll', 'Yes')}>Yes</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('payroll', 'No')}>No</a></li>
              </ul>
            </div>

            {/* VAT returns dropdown */}
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {formData.vatReturns || 'Need VAT returns completing?'}
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('vatReturns', 'Yes')}>Yes</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('vatReturns', 'No')}>No</a></li>
              </ul>
            </div>

            {/* Bookkeeping dropdown */}
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {formData.bookkeeping || 'Need us to complete your bookkeeping?'}
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('bookkeeping', 'Yes')}>Yes</a></li>
                <li><a className="dropdown-item" onClick={() => handleDropdownChange('bookkeeping', 'No')}>No</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="qoute-icon-btn">
        <div className="call-btn">
          <i className="fa-solid fa-calculator calculator-3"></i>
          <button className='qoute-btn'>Get An Instant Qoute</button>
        </div>
      </div>
    </div>
  );
};

export default Qoute;
