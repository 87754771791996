import React, { useState, useEffect } from 'react';
import About from '../components/About/About';
import Choose from '../components/Choose/Choose';
import Easy from '../components/Easy/Easy';
import Expertise from '../components/Expertise/Expertise';
import Footer from '../components/Footer/Footer';
import Homepage from '../components/Homepage/Homepage';
import MyServices from '../components/MyServices/MyServices';
import Navbar from '../components/Navbar/Navbar';
import Qoute from '../components/Qoute/Qoute';
import Popup from '../components/Popup/Popup';
import Scrollbar from '../components/Scrollbar/Scrollbar';
import './CSS/Home.css'
const Home = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [menu, setMenu] = useState("home")

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);
    useEffect(() => {
        document.title = 'Home | Faiz Accountax';
    }, []);
    return (

        <div className="App">
            <div className="navbar-homepage">
                <Navbar onOpenPopup={openPopup} />

                <Scrollbar onOpenPopup={openPopup} />
                <Popup isOpen={isPopupOpen} onClose={closePopup} />
                <Homepage />
            </div>
            <About />
            <Expertise />
            <Choose />

            <MyServices />
            <Easy />
            <Qoute />
            <Footer />



        </div>

    )
}

export default Home
