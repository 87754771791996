import React, { useState, useEffect } from 'react'
import './CSS/ContactPage.css'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Scrollbar from '../components/Scrollbar/Scrollbar'
import Popup from '../components/Popup/Popup';

const Contact = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);
  useEffect(() => {
    document.title = 'Contact Us | Faiz Accountax';
  }, []);
  return (
    <>
      <Navbar onOpenPopup={openPopup} />
      <Scrollbar onOpenPopup={openPopup} />
      <Popup isOpen={isPopupOpen} onClose={closePopup} />

      <div className="contactpage">
        <h1 className='contact-head'>Contact Us</h1>
        <hr />
        <div className='contact-container'>
          <div className="contact-left">
            <h1>Get In Touch</h1>
            <div className="info-list">
              <li>
                <i class="fa-solid fa-house"></i>
                <p>3 Greenhill, High Wycombe, HP13 5QJ</p>
              </li>
              <li>
                <i class="fa-solid fa-location-dot"></i>

                <p>
                  Suite 2685
                  124 City Road
                  London, EC1V 2NX</p>
              </li>
              <li>
                <i class="fa-solid fa-phone"></i>
                <p>0208 191 0732</p>
              </li>
              <li>
                <i class="fa-solid fa-envelope"></i>
                <p>   info@faizaccountax.com</p>



              </li>
            </div>
          </div>
          <div className="contact-right">
            <div className="name-field">
              <div className="label-field">
                <label htmlFor="first-name">First Name</label>
                <input type="text" name='first-name' placeholder='first name' />
              </div>
              <div className="label-field">
                <label htmlFor="last-name">Last Name</label>
                <input type="text" name='last-name' placeholder='last name' />
              </div>
            </div>
            <div className="label-field">
              <label htmlFor="email">Email</label>

              <input type="text" name='email' placeholder='example@gmail.com' />
            </div>
            <div className="label-field">
              <label htmlFor="subject">Subject</label>
              <input type="text" name='subject' placeholder='subject' />

            </div>

            <div className="label-field">
              <label htmlFor="subject">Message</label>
              <textarea name="textarea" placeholder='Tell us what we can help you with' id=""></textarea>
            </div>
            <button className='contact-form-btn'>Submit</button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Contact
