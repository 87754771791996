import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar/Navbar'
import MyServices from '../components/MyServices/MyServices'
import './CSS/Service.css'
import Footer from '../components/Footer/Footer'
import Scrollbar from '../components/Scrollbar/Scrollbar'
import Popup from '../components/Popup/Popup';

const Service = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [menu, setMenu] = useState("home")

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);
    useEffect(() => {
        document.title = 'Services | Faiz Accountax';
    }, []);
    return (
        <div className="servicepage" >
            <Scrollbar onOpenPopup={openPopup} />
            <Popup isOpen={isPopupOpen} onClose={closePopup} />

            <Navbar onOpenPopup={openPopup} />
            <MyServices />
            <div className="unsure-box">
                <h1>Unsure About Our Services? Schedule a Consultation</h1>
                <p>If you’re unsure which of our services best fits your needs, our team is here to help. Schedule a consultation with one of our experts to discuss your specific requirements and get personalized advice. We’ll guide you through our offerings and help you find the perfect solution for your accounting and tax requirements.</p>
                <button>Get A qoute</button>
            </div>
            <Footer />
        </div>
    )
}

export default Service
