import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import React, { useState } from 'react';
import Home from './Pages/Home';
import AboutPage from './Pages/AboutPage';
import ServicePage from './Pages/ServicePage';
import ContactPage from './Pages/ContactPage';
import Popup from './components/Popup/Popup';
import ScrollToTop from './components/ScrollToTop';
import BuisnessPage from './Pages/BuisnessPage';
import PersonalPage from './Pages/PersonalPage';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Popup isOpen={isPopupOpen} onClose={closePopup} />

      <Routes>
        <Route path='/' element={<Home onOpenPopup={openPopup} />} />
        <Route path='/about' element={<AboutPage onOpenPopup={openPopup} />} />
        <Route path='/services' element={<ServicePage onOpenPopup={openPopup} />} />
        <Route path='/contact' element={<ContactPage onOpenPopup={openPopup} />} />
        <Route path='/buisness' element={<BuisnessPage onOpenPopup={openPopup} />} />
        <Route path='/personal' element={<PersonalPage onOpenPopup={openPopup} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
