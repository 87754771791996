import React, { forwardRef } from 'react';
import './Footer.css'
const Footer = forwardRef((props, ref) => {
  return (
    <div ref={ref} id='contact' name="contact" className='footer'>
      <div className="footer-container">
        <div className="footer-container-left">
          <div className="services-section section">
            <h1 className='footer-heading'>Services</h1>
            <div className="footer-list">
              <li>Accounts</li>
              <li> Tax returns</li>
              <li>VAT returns</li>
              <li>Bookkeeping</li>
              <li>Payroll</li>
              <li>Self Assessment</li>
              <li>CIS returns</li>
              <li>Company formations</li>
            </div>
          </div>
          <div className="resources-section section">
            <h1 className='footer-heading'>Resources</h1>
            <div className="footer-list">
              <li>Plans and pricing</li>
              <li>Guides & templates</li>
              <li>Tax calculators</li>
              <li>Blog</li>
              <li>Instant quote</li>
              <li>Tax education centre</li>
              <li>Making Tax Digital support</li>
              <li>Help centre</li>
            </div>


          </div>
          <div className="about-section section">
            <h1 className='footer-heading'>About</h1>
            <div className="footer-list">

              <li>About us</li>
              <li>Meet the team</li>
              <li>Contact Us</li>
              <li>Careers</li>
              <li>Privacy policy</li>
              <li>Terms and conditions</li>
            </div>
          </div>
          <div className="contact-section contact">
            <h1 className='footer-heading'>Contact</h1>
            <div className="footer-list">


              <div className="footer-icon">
                <i class="fa-solid fa-phone icon-2"></i>
                <li>0208 191 0732</li>
              </div>
              <div className="footer-icon">
                <i class="fa-solid fa-envelope"></i>
                <li>info@faizaccountax.com</li>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container-right">
          <h1 className='footer-heading'>Contact</h1>
          <div className="footer-list">

            <div className="footer-icon">
              <i class="fa-solid fa-phone icon-2"></i>
              <li>0208 191 0732</li>
            </div>
            <div className="footer-icon">
              <i class="fa-solid fa-envelope"></i>
              <li>info@faizaccountax.com</li>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className='address'>
          <p>© 2024 Faiz Accountax. All rights reserved.</p>

        </div>
        <div className="adress-social">
          <p>
            Address:
            Faiz House
            3 Greenhill
            High Wycombe, HP13 5QJ

            Office Location
            Suite 2685
            124 City Road
            London, EC1V 2NX</p>
          <div className="social-icons">

            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-facebook-f"></i>
            <i class="fa-brands fa-linkedin-in"></i>
            <i class="fa-brands fa-twitter"></i>
          </div>
        </div>
      </div>

    </div>
  )
})

export default Footer
